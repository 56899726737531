<script setup>
import {Link, router} from '@inertiajs/vue3';
import {computed} from "vue";

const props = defineProps({
    href: {
        type: String,
        required: true
    },
    target: {
        type: String,
        default: '_self'
    },
});

const isExternalLink = computed(() => {
    return props.href.indexOf('#') !== -1 || props.target === '_blank';
});

const cssClasses = 'text-purple-600 font-medium hover:text-purple-800 dark:hover:text-purple-300 dark:text-purple-300 hover:underline'

</script>
<template>
    <a v-if="isExternalLink" :href :target :class="cssClasses">
        <slot/>
    </a>
    <Link
        v-else
        :href
        :class="cssClasses"
    >
        <slot/>
    </Link>
</template>
